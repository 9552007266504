<template>
  <div>
    <b-modal id="modal-session-app" ok-only ok-variant="warning" ok-title="Login" modal-class="modal-session-app" centered
      title="Expired !" @ok="gotologin()">
      <b-card-text>Session Expired</b-card-text>
    </b-modal>
    <b-card id="addAccountWrapper">
      <validation-observer ref="saveApp" #default="{ invalid }">
        <b-form @submit.prevent="saveApp(invalid)">
          <b-container>
            <b-row class="justify-content-md-center">
              <b-col cols="12" md="1">
                <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="flat-warning" class="btn-icon"
                  to="/myprofile/apps" v-b-tooltip.hover title="Go Back">
                  <feather-icon icon="ArrowLeftCircleIcon" size="20" />
                </b-button>
              </b-col>
              <b-col cols="12" md="11">
                <h1>Add Your App</h1>
                <small>Search for your app package name and save the app to your account.</small>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col md="10" class="ml-md-5" cols="12">
                <b-form-group label="Package name" label-for="app-id">
                  <validation-provider #default="{ errors }" name="Package name" rules="required">
                    <b-form-input size="md" id="app-id" v-model="appId" placeholder="Package name"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="10" class="ml-md-5" cols="12">
                <b-button type="submit" v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="outline-success"
                  class="mx-auto d-block w-50 mt-2" :disabled="invalid">
                  Get App Data
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card id="app-info" class="hidden">
      <b-container>
        <b-row class="align-items-center">
          <b-col class="col-sm-12 col-md-2">
            <img class="app-icon w-100" :src="icon" alt="" />
          </b-col>
          <b-col class="col-sm-12 col-md-7">
            <h2>
              <span class="app-title">{{ title }}</span>
              <span class="badge bg-primary rounded-pill mx-2 px-2 app-score">{{ score }} / 5</span>
            </h2>
            <p class="app-summary">{{ summary }}</p>
          </b-col>
          <b-col class="col-sm-12 col-md-3">
            <button class="btn btn-primary btn-lg" @click="saveAppInfo()">
              Save this app
            </button>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col class="col-sm-12 col-md-8">
            <h3>Description</h3>
            <p class="app-description mb-3" style="font-size: 13px"></p>
            <h3>Screenshots</h3>
            <b-row class="mb-3">
              <b-col cols="3"><img class="w-100" :src="screenshot1" /></b-col>
              <b-col cols="3"><img class="w-100" :src="screenshot2" /></b-col>
              <b-col cols="3"><img class="w-100" :src="screenshot3" /></b-col>
              <b-col cols="3"><img class="w-100" :src="screenshot4" /></b-col>
            </b-row>
          </b-col>
          <b-col class="col-sm-12 col-md-4">
            <h3>About</h3>
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <span class="text-muted">Version</span>
                <span class="app-version">{{ version }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <span class="text-muted">Released</span>
                <span class="app-released-date">{{ releasedDate }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <span class="text-muted">Ratings</span>
                <span class="app-released-date">{{ ratings }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <span class="text-muted">Installs</span>
                <span class="app-released-date">{{ installs }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <span class="text-muted">Genre</span>
                <span class="app-released-date">{{ genre }}</span>
              </li>
            </ul>
          </b-col>
        </b-row>

      </b-container>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, regex, url } from "@validations";

import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BFormGroup,
  BFormFile,
  BForm,
  VBTooltip,
  BButton,
  BFormSelect,
  BContainer,
  BModal,
  BCardText,
} from "bootstrap-vue";

import { deleteAccessTokenCookie } from "@/helper/helpers.js";
import { connectApi } from "@/helper/connectApi";
import axios from "@axios";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

export default {
  components: {
    // BCardCode,
    BFormSelect,
    BRow,
    BCol,
    BCard,
    BContainer,
    BFormInput,
    BFormGroup,
    BFormFile,
    BForm,
    VBTooltip,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BModal,
    BCardText
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },

  data() {
    return {
      appId: null,
      title: null,
      icon: null,
      score: null,
      releasedDate: null,
      version: null,
      summary: null,
      installs: null,
      ratings: null,
      account: null,
      genre: null,
      screenshot1: null,
      screenshot2: null,
      screenshot3: null,
      screenshot4: null,
      accountId: null,
      data: {},
      appInfo: {},
      // validation rules
      required,
      regex,
      url,
      token: null,
      server: 1,
    };
  },
  mounted() {

  },
  methods: {
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    async saveApp(invalid) {
      if (!/\./.test(this.appId)) {
        this.showToast(
          "danger",
          "AlertTriangleIcon",
          "Error",
          "Please make sure the Package name You've eneterd is correct!"
        );
        return;
      }
      this.$refs.saveApp
        .validate()
        .then(async (success) => {
          const appInfoCard = document.getElementById("app-info"),
            appDescription = document.querySelector(".app-description");
          appInfoCard.classList.add("hidden");
          // axios
          //   .get(
          //     `https://scx.clicklab.app/api/apps/${this.appId}/?fullDetail=true`
          //   )
          const response = connectApi(`https://scx.clicklab.app/api/apps/${this.appId}/?fullDetail=true`);
          response
            .then((response) => {
              const { status } = response.data;
              if (status === "Unauthorized") {
                deleteAccessTokenCookie();
                this.$root.$emit("bv::show::modal", "modal-session-app");
                return;
              }
              const data = response.data;
              return data;
            })
            .then((data) => {
              this.appInfo = data;
              this.title = data.title;
              this.icon = data.icon;
              this.releasedDate = data.released;
              this.score = data.scoreText;
              this.summary = data.summary;
              this.version = data.version;
              this.installs = data.maxInstalls;
              this.ratings = data.ratings;
              this.account = data.developer.devId;
              this.accountId = data.developerId;
              this.genre = data.genre;
              this.screenshot1 = data.screenshots[0];
              this.screenshot2 = data.screenshots[1];
              this.screenshot3 = data.screenshots[2];
              this.screenshot4 = data.screenshots[3];

              appDescription.innerHTML = data.descriptionHTML;

              appInfoCard.classList.remove("hidden");
            })
            .catch((error) => {
              this.showToast(
                "danger",
                "AlertTriangleIcon",
                "Error",
                "Please make sure the Package name You've eneterd is correct!"
              );
            });
        })
        .catch((exception) => {
          console.error(exception);
        });
    },
    async saveAppInfo() {
      // this.appInfo.user_token = 
      await axios
        .post("/store-app", {
          ...this.appInfo,
          account: this.account,
          account_id: this.accountId,

        })
        .then(({ data }) => {
          if (data.status == "success") {
            this.showToast("success", "SaveIcon", "Done", data.message);
            // this.$refs.resetButton.click();
            router.push({ name: "apps" });
          } else if (data.status === "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              data.message
            );
          } else if (data.status === "warning") {
            this.showToast(
              "warning",
              "AlertTriangleIcon",
              "Warning",
              data.message
            );
          }
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Something went wrong!"
          );
        });
    },
    gotologin() {
      this.$router.push({ name: "auth-login" });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/vue-select.scss";

#addAccountWrapper {
  width: 60% !important;
  display: block;
  margin-inline: auto;
  margin-top: 3rem;
  box-shadow: 8px 6px 7px rgba(0, 0, 0, 0.2) !important;

}

@media (max-width: 560px) {
  #addAccountWrapper {
    width: 100% !important;

  }
}
</style>
